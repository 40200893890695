.container_contact {
  display: flex;

  .contact_form {
    border-bottom: solid 2px rgba(255, 255, 255, 0.09);
    box-sizing: border-box;
    padding: 50px;
    width: 50%;

    .form_header {
      color: rgba(255, 255, 255, 0.86);
      font-family: "Poppins";
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 56px;
      padding-bottom: 30px;

      span:nth-child(3) {
        font-weight: 400;
      }

      img {
        height: 80px;
        margin-top: -8px;
        padding-left: 20px;
        position: absolute;
      }
    }

    .form_body {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .form_row {
        display: flex;
        justify-content: space-between;
      }

      .form_select {
        background: #0c2546;
        border: 0.5px solid rgba(255, 255, 255, 0.24);
        border-radius: 40px;
        box-sizing: border-box;
        font-family: "Poppins";
        height: 40px;
        padding: 0px 0 0 16px;
        width: 48%;
        color: #ffff;
        font-size: 12px;
        font-weight: 700;

        option[value="0"] {
          display: none;
          color: rgba(255, 255, 255, 0.34);
        }
      }

      .form_text_area {
        background: #0c2546;
        border: 0.5px solid rgba(255, 255, 255, 0.24);
        border-radius: 28px;
        box-sizing: border-box;
        font-family: "Poppins";
        height: 112px;
        padding: 12px 0 0 20px;
        width: 100%;
        color: #ffff;
        font-size: 12px;
        font-weight: 700;
      }

      .form_input {
        background: #0c2546;
        border: 0.5px solid rgba(255, 255, 255, 0.24);
        border-radius: 40px;
        box-sizing: border-box;
        font-family: "Poppins";
        height: 40px;
        padding: 0px 0 0 20px;
        width: 48%;
        color: #ffff;
        font-size: 12px;
        font-weight: 700;
      }

      .form_input::placeholder {
        color: rgba(255, 255, 255, 0.34);
        font-feature-settings: "liga" off, "clig" off;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 27px */
        letter-spacing: 0.09px;
      }

      .form_text_area::placeholder {
        color: rgba(255, 255, 255, 0.34);
        font-feature-settings: "liga" off, "clig" off;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 27px */
        letter-spacing: 0.09px;
      }

      .form_input:hover::placeholder {
        color: #fff;
      }

      .form_text_area:hover::placeholder {
        color: #fff;
      }

      .form_input:focus::placeholder {
        color: #fff;
      }

      .form_text_area:focus::placeholder {
        color: #fff;
      }

      .form_input:focus {
        outline: none;
      }

      .form_text_area:focus {
        outline: none;
      }

      .form_select:focus {
        outline: none;
      }
    }

    .form_footer {
      padding-top: 30px;
      button {
        background: linear-gradient(90deg, #1c06f4 0%, #e600ff 100%);
        border: none;
        border-radius: 30px;
        color: #fff;
        cursor: pointer;
        font-size: 18px;
        font-family: "Poppins";
        font-weight: 800;
        height: 59px;
        line-height: 27px;
        width: 136px;
      }

      button:disabled {
        // background: linear-gradient(90deg, #786af4 0%, #e867f7 100%);
        // color: #5a5a65;
        cursor: not-allowed;
      }

      span {
        color: #fff;
        margin-left: 20px;
        border-radius: 30px;
        border: 1px solid;
        padding: 7px 20px;
      }
    }
  }

  .cart {
    display: flex;
    padding-top: 0px;
    position: relative;
    width: 50%;

    .cart_shadow {
      background-color: rgba(8, 29, 63, 0.5);
      display: flex;
      margin-right: 50px;
      padding-top: 60px;
      width: 100%;
    }

    .cart_content {
      position: absolute;
      display: flex;
      right: 13px;
      top: 64px;
    }

    .cart_title {
      align-items: flex-end;
      display: flex;
      flex-direction: column;
      width: 100%;
      z-index: 2;

      span {
        align-content: center;
        background-color: rgb(255, 255, 255);
        border: 1px solid rgba(134, 134, 134, 0.751);
        border-radius: 40px;
        color: #011E41;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        height: 54px;
        letter-spacing: 0.42px;
        line-height: 21px;
        text-align: center;
        text-shadow: 0px 8px 8px rgba(0, 0, 0, 0.4);
        width: 149px;
      }

      span {
        margin: -2px 24px 0 0;
      }
    }

    .cart_logo {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 20px;
      z-index: 2;

      img {
        cursor: pointer;
        height: auto;
        width: 50px;
      }

      .news_logo {
        border: 6px solid rgba(255, 255, 255, 0.11);
        border-radius: 40px;
        height: auto;
        width: 80px;
      }
    }

    .marker_container {
      left: 220px;
      position: absolute;
      top: 400px;

      .address {
        border-radius: 4px;
        background: #fff;
        padding: 10px;
        width: 228px;

        span {
          color: #353535;
          font-family: "Poppins";
          font-size: 9px;
          font-style: normal;
          font-weight: 600;
          line-height: 15px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container_contact {
    .cart {
      .cart_title {
        span {
          margin: 4px 24px 0 0;
          font-size: 12px;
          height: 30px;
          width: 120px;
        }
      }

      .cart_logo {
        img {
          width: 40px;
        }
      }

      .marker_container {
        img {
          width: 25px;
        }

        .address {
          padding: 5px;
          width: 130px;

          span {
            font-size: 7px;
            display: flex;
          }
        }
      }
    }

    .contact_form {
      .form_header {
        font-size: 35px;

        img {
          height: 70px;
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .container_contact {
    .cart {
      .cart_logo {
        .news_logo {
          width: 40px;
        }
      }

      .marker_container {
        left: 182px;
        top: 370px;
      }
    }

    .contact_form {
      padding: 20px;
      .form_header {
        font-size: 25px;
        padding-bottom: 20px;
        img {
          height: 55px;
          margin-top: 2px;
        }
      }

      .form_body {
        .form_row {
          .form_input {
            width: 48%;
          }

          .form_select {
            width: 48%;
          }
        }
      }

      .form_footer {
        span {
          margin-left: 10px;
          border-radius: 30px;
          border: 1px solid;
          padding: 6px 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 890px) {
  .container_contact {
    flex-direction: column-reverse;
    .contact_form {
      width: 100%;
      .form_body {
        .form_row {
          .form_input {
            font-size: 14px;
            width: 48%;
          }
          .form_input::placeholder {
            font-size: 14px;
          }

          .form_select {
            font-size: 14px;
            width: 48%;
          }

          .form_text_area {
            font-size: 14px;
          }

          .form_text_area::placeholder {
            font-size: 14px;
          }
        }
      }

      .form_footer {
      }
    }

    .cart {
      width: 100%;
      height: 600px;

      .cart_title {
        span {
          font-size: 14px;
          margin: -4px 22px 0 0;
          height: 54px;
          width: 150px;
        }
      }

      .cart_logo {
        height: 380px;
        img {
          cursor: pointer;
          height: auto;
          width: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 650px) {
  .container_contact {
    .contact_form {
      padding: 24px 20px 40px 20px;
      .form_body {
        .form_row {
          box-sizing: border-box;
          flex-direction: column;
          gap: 20px;
          .form_input {
            height: 60px;
            padding: 0 0 0 30px;
            width: 100%;
          }

          .form_select {
            height: 60px;
            padding: 0 0 0 26px;
            width: 100%;
          }

          .form_text_area {
            padding: 12px 0 0 30px;
          }
        }
      }

      .form_footer {
        text-align: right;
      }
    }

    .cart {
      height: 450px;
      width: 100%;
      .cart_title {
        margin-top: 2px;
        span {
          font-size: 14px;
          height: 40px;
          width: 140px;
        }
      }

      .cart_logo {
        height: 380px;
        img {
          cursor: pointer;
          height: auto;
          width: 50px;
        }
      }
    }
  }
}
