@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_home {
  background: linear-gradient(108deg, #003595 0%, #521B7D 62.53%, #A50064 126.32%);
}
.container_home_bg {
  background-image: url("../../assets/images/home3_bg.png");
  // background-size: cover;
  // background-position: center;
  background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  height: 900px;
  .container_home_body {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .home_bg {
    position: absolute;
    top: 0;
    z-index: 0;
  }

  .container_name_bg {
    display: contents;
  }

  .home_name_bg {
    align-self: center;
    position: absolute;
    top: 39%;
    z-index: 1;
  }

  .home_footer {
    display: flex;
    padding-top: 11%;
    place-content: end;
    width: 100%;

    img {
      z-index: 1;
      width: 30%;
      height: auto;
    }
  }

  .home_title {
    padding-top: 260px;
    text-align: center;
    width: 100%;
    z-index: 2;

    label {
      color: #ffff;
      font-family: "Poppins";
      font-size: 58px;
      font-weight: 700;
      line-height: 80px;
      text-decoration: underline;
    }
  }

  .home_subtitle_container {
    align-items: center;
    display: flex;
    gap: 86px;
    justify-content: center;
    padding-top: 7px;
    width: 100%;
    z-index: 3;
  }

  .home_subtitle {
    align-self: flex-end;
    color: #ffff;
    display: flex;
    flex-direction: column;
    font-family: "Poppins";
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: center;
    z-index: 3;

    span:first-of-type {
      font-size: 18px;
    }

    .icon1 {
      width: 54px;
    }

    .icon2 {
      margin-bottom: 2px;
      width: 70px;
    }

    .icon3 {
      width: 54px;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .container_home_bg {
    height: 750px;
    .home_name_bg {
      top: 36%;
      width: 800px;
    }

    .home_title {
      padding: 216px 0 4px 0;
      label {
        font-size: 54px;
      }
    }

    .home_subtitle_container {
      padding-top: 0px;
      margin-top: -10px;
    }

    .home_subtitle {
      font-size: 14px;

      span:first-of-type {
        font-size: 16px;
      }

      .icon1 {
        width: 43px;
      }

      .icon2 {
        width: 55px;
      }

      .icon3 {
        width: 45px;
      }
    }
  }
}

@media only screen and (max-width: 1330px) {
  .container_home_bg {
    .home_title {
      padding: 198px 0 4px 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .container_home_bg {
    height: 700px;
    .home_name_bg {
      top: 29%;
      width: 750px;
    }

    .home_title {
      padding: 176px 0 4px 0;
      label {
        font-size: 50px;
      }
    }

    .home_subtitle_container {
      gap: 70px;
      padding-top: 0px;
      margin-top: -10px;
    }

    .home_subtitle {
      line-height: normal;
      .icon1 {
        width: 39px;
      }

      .icon2 {
        width: 51px;
      }

      .icon3 {
        width: 41px;
      }
    }

    .home_footer {
      padding-top: 9%;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .container_home_bg {
    height: 612px;
    .home_name_bg {
      top: 22%;
      width: 650px;
    }

    .home_title {
      padding: 154px 0 4px 0;
      label {
        font-size: 45px;
      }
    }

    .home_subtitle_container {
      gap: 48px;
      padding-top: 0px;
      margin-top: -18px;
    }

    .home_subtitle {
      font-size: 12px;

      span:first-of-type {
        font-size: 14px;
      }

      .icon1 {
        width: 34px;
      }

      .icon2 {
        width: 45px;
      }
      .icon3 {
        width: 38px;
      }
    }

    .home_footer {
      padding-top: 12%;
    }
  }
}

@media only screen and (max-width: 890px) {
  .container_home_bg {
    height: 520px;
    .home_name_bg {
      top: 27%;
      width: 500px;
    }

    .home_title {
      padding: 78px 0 0px 0;
      label {
        font-size: 45px;
      }
    }

    .home_subtitle_container {
      gap: 40px;
      padding-top: 0px;
      margin-top: -20px;
    }

    .home_subtitle {
      font-size: 12px;

      span:first-of-type {
        font-size: 14px;
      }
      .icon1 {
        width: 30px;
      }

      .icon2 {
        width: 38px;
      }
      .icon3 {
        width: 28px;
      }
    }

    .home_footer {
      padding-top: 10%;
    }
  }
}

@media only screen and (max-width: 600px) {
  .container_home_bg {
    height: 820px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    .home_name_bg {
      top: 34%;
      width: 300px;
    }

    .home_title {
      padding: 204px 0 0px 0;
      label {
        font-size: 29px;
      }
    }

    .home_subtitle_container {
      gap: 18px;
      padding-top: 0px;
      margin-top: -8px;
    }

    .home_subtitle {
      font-size: 8px;

      span:first-of-type {
        font-size: 10px;
      }
      .icon1 {
        width: 22px;
      }

      .icon2 {
        width: 30px;
      }
      .icon3 {
        width: 24px;
      }
    }

    .home_footer {
      img {
        width: 45%;
      }
    }

    .home_title label {
      opacity: 8; /* Por defecto visible */
      transition: opacity 0.5s ease; /* Suaviza el cambio de opacidad */
    }

    /* Opcional: Clase para ocultar el texto antes de que se borre */
    .hidden {
      opacity: 50;
    }

    .home_title label {
      text-decoration: none; // Asegúrate de que el texto no esté subrayado
    }
  }
}

@media only screen and (max-width: 550px) {
  .container_home_bg {

    .home_title {
      label {
        font-size: 24px;
      }
    }
  }}