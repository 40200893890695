@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.container_products {
  .header_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(106.16deg, #a50064 0%, #003595 100%);
    border-radius: 7px;
    border: none;
    color: #fff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    height: 44px;
    margin-right: 20px;
    width: 187px;
    cursor: pointer;
  }

  .header_btn_en {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(106.16deg, #a50064 0%, #003595 100%);
    border-radius: 7px;
    border: none;
    color: #fff;
    font-family: "Poppins";
    font-size: 14px;
    font-weight: 700;
    line-height: 21px;
    height: 44px;
    margin-right: 20px;
    width: 146px;
    cursor: pointer;
  }

  .logo_btn {
    height: 20px;
  }

  .header_container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 64px;

    .header_title {
      color: #fff;
      font-size: 45px;
      font-weight: 400;
      line-height: 56.03px;
      padding: 40px;

      span {
        font-family: "Poppins";
      }

      span:nth-child(2) {
        font-weight: 700;
      }

      img {
        margin-top: -18px;
        padding-left: 50px;
        height: 93px;
        position: absolute;
      }
    }

    .header_subtile {
      border: 1px solid #ffffff42;
      border-radius: 27px 0 0 27px;
      box-shadow: 0px -7px 11px 0px #a48fff1f inset;
      color: #fff;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 20px;
      margin: 45px 0 45px 60px;
      place-items: center;
      width: 420px;
      span {
        font-family: "Poppins";
        padding: 4px 20px 4px 20px;
      }
    }
  }

  .body_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    .video_row {
      display: flex;
      width: 100%;

      .video {
        align-content: center;
        width: 50%;
        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .cart_container {
      box-sizing: border-box;
      width: 50%;
      background-color: #fff;
      padding: 60px 50px;

      .cart_header {
        display: flex;
        font-size: 45px;
        font-weight: 700;
        justify-content: space-between;
        line-height: 56.03px;
        padding: 30px 0px 18px 0px;
        text-align: left;
        text-shadow: 3px 4px 2px rgba(0, 0, 0, 0.1);
        span {
          font-family: "Poppins";
        }
        .cart_header_subtitle {
          font-weight: 300;
        }

        .cart_header_img {
          align-items: center;
          display: flex;
          justify-content: flex-end;

          .second_img_header {
            height: 40px;
          }
        }

        .title_img {
          margin-top: -16px;
          position: absolute;
          height: 93px;
        }
      }

      .cart_body {
        font-size: 18px;
        font-weight: 600;
        text-align: left;
        color: #011e41;

        p {
          font-family: "Poppins";
        }
        .cart_line_breack {
          span {
            display: inline;
          }
          span::after {
            content: "";
            display: block;
          }
        }
      }

      .cart_footer {
        padding-top: 22px;

        button {
          border: 0.2px solid #b0b8c2;
          border-radius: 30px;
          font-family: "Poppins";
          line-height: 27px;
          padding: 0px 61px 0px 20px;
          height: 59px;
          font-size: 18px;
        }
        .first_btn {
          background: #f7f9ff;
          color: #011E41;
          font-weight: 300;
          text-align: left;
        }

        .second_btn {
          background: linear-gradient(90deg, #1c06f4 0%, #e600ff 100%);
          color: #fff;
          cursor: pointer;
          font-weight: 800;
          margin-left: -48px;
          padding-left: 15px;
          padding-right: 15px;
        }

        .third_btn {
          border-radius: 999px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          color: #fff;
        }
      }
    }

    .cart_container_blue {
      background-image: url("../../assets/images/ainsurance_bg.png");
      background-repeat: round;
      opacity: 0.9;

      .cart_header {
        color: #fff;
      }

      .cart_body {
        color: #fff;
        text-shadow: 3px 4px 2px rgba(0, 0, 0, 0.1);
      }

      .cart_footer {
        .first_btn {
          background: rgba(255, 255, 255, 0.3);
          border-radius: 999px;
        }
      }
    }
  }

  @media only screen and (max-width: 1650px) {
    .body_container {
      .cart_container {
        padding: 40px 30px;
      }
    }
  }

  @media only screen and (max-width: 1400px) {
    .body_container {
      .cart_container {
        padding: 25px 18px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .header_container {
      .header_title {
        font-size: 28px;
        padding: 32px;
        img {
          margin-top: -12px;
          padding-left: 20px;
          height: 80px;
        }
      }
    }

    .body_container {
      .cart_container {
        .cart_header {
          font-size: 26px;

          .title_img {
            height: 80px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1470px) {
    .body_container {
      .cart_container {
        .cart_header {
          font-size: 30px;
        }

        .cart_body {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 1280px) {
    .body_container {
      .cart_container {
        .cart_header {
          font-size: 30px;
        }

        .cart_body {
          font-size: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .body_container {
      .cart_container {
        .cart_header {
          .title_img {
            margin-top: -10px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .header_container {
      .header_title {
        font-size: 20px;
        padding: 20px;
        img {
          margin-top: -4px;
          padding-left: 16px;
          height: 70px;
        }
      }

      .header_subtile {
        font-size: 14px;
        margin: 26px 0 20px 50px;
        width: 380px;
      }
    }

    .body_container {
      .cart_container {
        .cart_header {
          font-size: 24px;

          .title_img {
            margin-top: -8px;
            height: 70px;
          }
        }

        .cart_body {
          font-size: 12px;
        }
      }
    }
  }

  @media only screen and (max-width: 960px) {
    .header_container {
      .header_title {
        font-size: 22px;
        padding: 20px;
        img {
          margin-top: -6px;
          padding-left: 16px;
        }
      }

      .header_subtile {
        width: 350px;
      }
    }

    .body_container {
      .cart_container {
        .cart_header {
          font-size: 22px;
          padding-top: 0px;
        }

        .cart_body {
          font-size: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    .header_container {
      .header_subtile {
        font-size: 13px;
      }
    }
  }

  @media only screen and (max-width: 910px) {
    .header_container {
      .header_subtile {
        font-size: 12px;
      }
    }
  }

  @media only screen and (max-width: 931px) {
    .header_container {
      flex-direction: column-reverse;
      gap: 0px;

      .header_title {
        padding: 0px 0 20px 15px;
      }

      .header_subtile {
        margin: 15px 0;
        align-self: self-end;
      }
    }

    .body_container {
      .video_row {
        flex-direction: column;

        .cart_container {
          width: 100%;
          padding: 15px;

          .cart_header {
            padding: 0px;
            width: 100%;

            .second_img_header {
              display: none;
            }

            .title_img {
              height: 50px;
              margin-top: 3px;
            }
          }

          .cart_footer {
            padding: 22px 0px;
            text-align: center;

            .first_btn {
              font-size: 14px;
              padding: 16px 52px 16px 16px;
            }

            .second_btn {
              font-size: 14px;
              margin-left: -45px;
              padding: 16px 22px;
            }
          }
        }

        .video {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 530px) {
    .body_container {
      .video_row {
        .cart_container {
          .cart_header {
            flex-direction: column-reverse;

            .cart_header_img {
              justify-content: flex-start;
              padding-bottom: 25px;
            }
          }
        }
      }
    }
  }
}
