@font-face {
  font-family: "Poppins";
  src: url("../../../public/fonts/POPPINS.ttf");
}

.header_container {
  .long_menu {
    align-items: center;
    color: white;
    display: flex;
    flex-direction: row;
    font-weight: normal;
    gap: 20px;
    justify-content: space-between;
    padding: 80px 45px 0 45px;

    .menu_button {
      background-color: transparent;
      border: none;
      color: white;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 900;
      line-height: 31.5px;
      cursor: pointer;
      text-align: center;
    }

    .menu {
      cursor: pointer;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 31.5px;
      text-align: center;
      align-self: center;
      padding: 0 5px;
    }

    .menu_is_active {
      font-weight: 700;
    }

    .menu:hover {
      border-radius: 8px;
      background: linear-gradient(
          180deg,
          rgba(169, 163, 194, 0.2) 0%,
          rgba(169, 163, 194, 0.05) 100%
        ),
        rgba(255, 255, 255, 0.05);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .submenu_container {
      text-align-last: start;
      display: flex;
      flex-direction: column;
      position: absolute;
      border-radius: 8px;
      background: linear-gradient(
          180deg,
          rgba(169, 163, 194, 0.2) 0%,
          rgba(169, 163, 194, 0.05) 100%
        ),
        rgba(255, 255, 255, 0.05);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      padding: 8px 0px;
      margin-top: 2px;
      z-index: 3;
    }

    .submenu {
      align-self: flex-start;
      box-sizing: border-box;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 2px 10px;
      text-align: center;
      width: 100%;
    }

    .submenu:hover {
      border-radius: 8px;
      background: linear-gradient(
          180deg,
          rgba(169, 163, 194, 0.2) 0%,
          rgba(169, 163, 194, 0.05) 100%
        ),
        rgba(255, 255, 255, 0.05);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-weight: 900;
    }

    img {
      height: 40px;
      object-fit: contain;
      width: min-content;
    }
  }

  .short_menu {
    display: none;
  }
}

@media only screen and (max-width: 1400px) {
  .header_container {
    .long_menu {
      padding: 50px 45px 0 45px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .header_container {
    .long_menu {
      padding: 46px 45px 0 45px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .header_container {
    .long_menu {
      padding: 45px 20px 0 20px;

      .menu {
        font-size: 15px;
        line-height: normal;
      }

      .menu_button {
        font-size: 15px;
        line-height: normal;
      }

      .submenu {
        font-size: 10px;
      }
    }
  }
}

@media only screen and (max-width: 890px) {
  .header_container {
    .long_menu {
      display: none;
    }
    .short_menu {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 35px;
    }

    .icon_mobile {
      height: 48px;
      cursor: pointer;
      position: relative;
      z-index: 4;
    }

    .logo_mobile {
      height: 38px;
    }

    .language_container {
      display: flex;
      gap: 10px;
    }

    .mobile_menu_container {
      position: relative;
    }

    .mobile_menu_wrapper {
      position: absolute;
      display: flex;
      flex-direction: column;
      border-radius: 24px 8px 8px 8px;
      background: linear-gradient(
          180deg,
          rgba(169, 163, 194, 0.2) 0%,
          rgba(169, 163, 194, 0.05) 100%
        ),
        rgba(255, 255, 255, 0.17);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      top: 0;
      padding: 50px 20px 8px 15px;
      width: 345px;
      align-items: flex-start;
      gap: 3px;
      span {
        color: #fff;
        cursor: pointer;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        z-index: 3;
        padding: 5px 15px;
        width: 80%;
      }

      span:hover {
      border-radius: 8px;
      background: linear-gradient(
          180deg,
          rgba(169, 163, 194, 0.2) 0%,
          rgba(169, 163, 194, 0.05) 100%
        ),
        rgba(255, 255, 255, 0.05);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      font-weight: 900;
    }
    }

    .menu_mobile {
      color: #fff;
      cursor: pointer;
      text-align: center;
      font-family: Poppins;
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    .menu_mobile_is_active {
      font-weight: 900;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header_container {

    .short_menu {
      padding: 50px 40px;
    }
}}

@media only screen and (max-width: 550px) {
  .header_container {

    .short_menu {
      padding: 50px 10px;
    }
}}